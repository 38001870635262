import { getName as getClientName } from '../common/ClientUtil';
import axios from "axios";

export const selectAddressFromList = (addressId, addressList) => {
    let address = null;
    if (addressList && addressList.length > 0 && addressId) {
        addressList.forEach(item => {
            if (item.id === addressId) {
                address = {                                    
                    value: item.id,
                    label: item.address1,
                    address1: item.address1,
                    address2: item.address2,
                    city: item.city,
                    state: item.state,
                    postalCode: item.postalCode,
                    country: item.country,
                    isFirst: item.isFirst,
                    link: item.link 
                }
        }})
    } 
    return address;
}

export const selectContactFromList = (contactId, contactList) => {
    let contact = null;
    if (contactList && contactList.length > 0 && contactId) {
        contactList.forEach(item => {
            if (item.id === contactId) {
                contact = {                                    
                    value: item.id,
                    label: getClientName(item),
                    title: item.title,
                    contactInfos: item.contactInfos,
                    isFirst: item.isFirst,
                    link: item.link
                }
        }})
    } 
    return contact;
}

export const getName = (item) => {
    let name = '';
    if (item.firstName) {
        name = item.firstName;
    }
    if (item.firstName && item.lastName) {
        name = item.firstName + " " + item.lastName
    }
    return name;
}

export const getCompanyDetails = async (searchTerm, requiredData, clientTypeIds) => {     
    //searchTerm = searchTerm.replace(/\W/g, '');     
    let clientList = []                          
    let clientId = [1];//by default search in clients              
    let _requiredData = requiredData ? requiredData : 20;
    const url = '/api/client/getCompanyDetails';
    let _clientTypeIds = clientTypeIds ? clientTypeIds : clientId;
    if ( _requiredData && _clientTypeIds) {
        try {
            const res = await axios.post(url, { searchTerm, _requiredData, _clientTypeIds });
            clientList = res.data;
        } catch (error) {
            if (error.response) {
                toastr.error(error.response.status);
            } else if (error.request) {
                toastr.error("Request error" + error.request);
            } else {
                toastr.error('Error', error.message);
            }
        }
    } 
    return clientList;
}

export const setOptions = (clientList, onlyPrimaryAddress) => {    
    return clientList.map(client => ({
        value: client.id,
        label: client.name,
        addresses: onlyPrimaryAddress ? client.addresses.filter(address => address.isPrimary === true) : _.orderBy(client.addresses, ['isPrimary'],['desc']), 
        contacts: client.contacts,
        isFirst: client.isFirst,
        link: client.link
    }));
}

export const getOptionComponent = (props) => {
    let address = props.data.addresses && props.data.addresses[0];
    return (
        <div>
        {props.data.isFirst === true && props.data.link}
        {!props.data.isFirst === true && <h5>{props.data.label}</h5>}
        {!props.data.isFirst === true && <p>{address && (address.address1 +", " + address.address2)}</p>}
        {!props.data.isFirst === true && <p>{address && address.city && (address.city + ", " + address.state + ", " + address.postalCode)}</p>}
        {!props.data.isFirst === true && <p>{props.data.title && props.data.title}</p>}
        </div>
    )
}

