import React, { Component } from "react";
import { actionCreators as clientActions } from "../../store/Clients";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AxisLazyLoad from "../common/AxisLazyLoad";
import { components } from 'react-select';
import { getCompanyDetails, setOptions, getOptionComponent } from "./ClientUtil";
import AxisAsyncSelect from "./AxisAsyncSelect";
const ClientCreateModal = AxisLazyLoad({ loader: () => import('../Client/ClientCreateModal') });
const errorOption= Promise.resolve([
        { value: '0', label: 'Error.. Please pass api url and search text..', disabled: true, }
    ]);
 class ClientSelect extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            selectedValue: "",            
            typing: false,
            typingTimeout: 0,
            input: "",
            clientCreateModalVisible: false,
            isClientCreateRendered: false,
            errors: {}
        };       
        this.timer = null;
    }

    async  componentWillReceiveProps(nextProps, nextContext) {  
        if (this.props.clientId !== nextProps.clientId) {            
            if (nextProps.clientId) {
                await this.props.clientActions.getSingle(nextProps.clientId);
            }
            else
                await this.setState({ selectedValue: "" });

            if (this.props.selectedClient && nextProps.clientId) {
                let fetchedCliet = this.props.selectedClient;
                fetchedCliet.value = this.props.selectedClient.id;
                fetchedCliet.label = this.props.selectedClient.name;                
                await this.setState({ selectedValue: fetchedCliet });
            }
            await this.setState({ errors: nextProps.errors });
        }
    }   
    
    getOptions = inputValue =>       
            new Promise(resolve => {
                setTimeout(() => {
                    resolve({ options: this.filterOptions(inputValue) });
                }, 1000);
            }); 
     onChange = async (selected) => {
        if (!selected?.isFirst) {
            await this.setState({ selectedValue: selected });
            await this.props.selectClient(selected);
        }
    }
     //can i wait up to when user stop typing
    changeName = (event) => {
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }
        self.setState({
            input: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.sendToParent(self.state.name);
            }, 5000)
        });
    }

    addClientClick = async () => {
        await this.setState({ clientCreateModalVisible: true, isClientCreateRendered: true });
    };

    dismissClientModal = async () => {
        await this.setState({ clientCreateModalVisible: false });       
    };

    getOptions = async (searchTerm) => {
        //searchTerm = searchTerm.replace(/\W/g, '');
        let clientList = [];
        //just check lenght after which api hit
        if (searchTerm) {
            clientList = await getCompanyDetails(searchTerm, this.props.requiredData, this.props.clientTypeIds);
        }
        else {
            clientList.unshift({
                isFirst: true,
                link: <h5><a onClick={this.addClientClick}>+ Add Company</a></h5>,
                addresses: [],
                contacts: [],
            });
        }
        return setOptions(clientList, true);
    };

    render() {
        const Option = (props) => {
            return (
                <components.Option {...props}>
                    { getOptionComponent(props) }
                </components.Option>
            );
        }

        return (          
            <div>               
                <AxisAsyncSelect
                    defaultOptions={true}
                    cacheOptions={true}
                    isClearable={true}
                    className="basic-single"
                    classNamePrefix="select"
                    name="clientSearch"
                    labelKey="label"
                    valueKey="value"
                    value={this.state.selectedValue}
                    matchPos="any"
                    loadOptions={this.getOptions.bind(this)}
                    onChange={this.onChange}
                    components={{Option}}
                    isDisabled={this.props.disabled}
                    isOptionDisabled={option => option?.disabled}
                    hasError={this.props.hasError}
                />
                {this.state.isClientCreateRendered &&
                    <ClientCreateModal
                    isVisible={this.state.clientCreateModalVisible}
                    buttonRightClick={this.dismissClientModal}
                    clientSaved={this.props.clientSaved}
                    selectClient={this.props.selectClient}
                    />
                }
            </div>
        );
    }
}
ClientSelect.propTypes = {};
ClientSelect.defaultProps = {};
const mapStateToProps = state => ({   
    isLoading: state.clients.isLoading,
    selectedClient: state.clients.selected,
});
const mapDispatchToProps = dispatch => ({
    clientActions: bindActionCreators(clientActions, dispatch),   
});
export default connect(mapStateToProps, mapDispatchToProps)(ClientSelect);