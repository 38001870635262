import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import * as Counter from './Counter';
import * as WeatherForecasts from './WeatherForecasts';
import * as UserAuthentication from "./UserAuthentication";
import * as Claims from "./Claims";
import * as UserTypes from "./UserTypes";
import * as Users from "./Users";
import * as Projects from "./Projects";
import * as Clients from "./Clients";
import * as ContactTypes from "./ContactTypes";
import * as ClientTypes from "./ClientTypes";
import * as Countries from "./Countries";
import * as States from "./States";
import * as Modal from "./Modal";
import * as Proposal from "./Proposal";
import * as ProjectType from "./ProjectType";
import * as ProposalStatus from "./ProposalStatus";
import * as PurchaseOrderStatus from "./PurchaseOrderStatus";
import * as ProjectStatus from "./ProjectStatus";
import * as File from "./File";
import * as Notification from "./Notifications";
import * as PurchaseOrders from "./PurchaseOrder";
import * as PurchaseOrderItems from "./PurchaseOrderItem";
import * as PurchaseOrderShipments from "./PurchaseOrderShipment";
import * as ShipmentMethod from "./ShipmentMethod";
import * as ShipmentStatus from "./ShipmentStatus";
import * as Shipment from "./Shipment";
import * as ReceivingAlerts from "./ReceivingAlerts";
import * as ReceivingAlertItem from "./ReceivingAlertItem";
import * as inventoryItem from "./InventoryItem";
import * as ShipmentReceivingAlert from "./ShipmentReceivingAlert";
import * as PurchaseOrderReceivingAlert from "./PurchaseOrderReceivingAlert";
import * as ChargeType from "./ChargeTypes";
import * as ChargeCategory from "./ChargeCategories";
import * as Charge from "./Charges";
import * as ProjectReceivingAlert from "./ProjectReceivingAlert";
import * as CommonApis from "./CommonApis";
import * as Items from "./Items";
import * as ShipmentItems from "./ShipmentItem";
import * as Invoice from "./Invoices";
import * as Inventory from "./Inventory";
import * as Warehouserates from "./WarehouseRate";
import * as PicTickets from "./PickTicket";
import * as PickTicketItems from "./PickTicketItem";
import * as DataFlows from "./DataFlow";
import * as ForgotPassword from "./ForgotPassword";
import * as ResetPassword from "./ResetPassword";

const reducers = {
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    userAuthentication: UserAuthentication.reducer,
    forgotPassword: ForgotPassword.reducer,
    resetPassword: ResetPassword.reducer,
    claims: Claims.reducer,
    userTypes: UserTypes.reducer,
    users: Users.reducer,
    projects: Projects.reducer,
    clients: Clients.reducer,
    contactTypes: ContactTypes.reducer,
    clientTypes: ClientTypes.reducer,
    states: States.reducer,
    countries: Countries.reducer,
    modal: Modal.reducer,
    proposals: Proposal.reducer,
    projectTypes: ProjectType.reducer,
    proposalStatuses: ProposalStatus.reducer,
    files: File.reducer,
    notifications: Notification.reducer,
    projectStatuses: ProjectStatus.reducer,
    purchaseOrderStatuses: PurchaseOrderStatus.reducer,
    purchaseOrderItems: PurchaseOrderItems.reducer,
    purchaseOrders: PurchaseOrders.reducer,
    purchaseOrderShipments: PurchaseOrderShipments.reducer,
    shipmentMethods: ShipmentMethod.reducer,
    shipmentStatuses: ShipmentStatus.reducer,
    shipments: Shipment.reducer,
    receivingAlerts: ReceivingAlerts.reducer,
    rAItems: ReceivingAlertItem.reducer,
    inventoryItems: inventoryItem.reducer,
    chargeTypes: ChargeType.reducer,
    chargeCategories: ChargeCategory.reducer,
    charges: Charge.reducer,
    shipmentReceivingAlerts: ShipmentReceivingAlert.reducer,
    purchaseOrderReceivingAlerts: PurchaseOrderReceivingAlert.reducer,
    projectRAs: ProjectReceivingAlert.reducer,
    commonApis: CommonApis.reducer,
    items: Items.reducer,
    shipmentItems: ShipmentItems.reducer,
    invoices: Invoice.reducer,
    inventories: Inventory.reducer,
    warehouserates: Warehouserates.reducer,
    pickTickets: PicTickets.reducer,
    pickTicketItems: PickTicketItems.reducer,
    dataFlows: DataFlows.reducer,
};

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    ...reducers,
})
export default createRootReducer