import AsyncSelect from "react-select/async";
import { getCustomStyles } from "./SelectCustomStyles";

export default function AxisAsyncSelect(props) {
    return (
        <AsyncSelect
            defaultOptions={props.defaultOptions}
            cacheOptions={props.cacheOptions}
            isClearable={props.isClearable}
            value={props.value}
            onChange={props.onChange}
            matchPos={props.matchPos}
            valueKey={props.valueKey}
            labelKey={props.labelKey}
            name={props.name}
            title={props.title}
            className={props.className}
            classNamePrefix={props.classNamePrefix}
            loadOptions={props.loadOptions}
            isDisabled={props.isDisabled}
            isOptionDisabled={option => option?.disabled}
            components={props.components}
            optionRenderer={props.optionRenderer}
            styles={getCustomStyles(props.hasError)}
        />
    )
}