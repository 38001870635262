

export const getCustomStyles = (hasError, height) => {
    return {
        control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: hasError ? 'red' : baseStyles.borderColor,
        minHeight: height ? height : '34px', // Adjust this value to your desired height
        height: height ? height : '34px', // Adjust this value to your desired height
        overflow: 'hidden',
        }),
        valueContainer: (provided, state) => ({
        ...provided,
        height: height ? height : '34px', // Adjust this value to match the control height
        padding: '0 6px',
        }),
        input: (provided, state) => ({
        ...provided,
        margin: '0px'
        }),
        indicatorsContainer: (provided, state) => ({
        ...provided,
        height: height ? height : '34px', // Adjust this value to match the control height
        display: 'flex'
        }),
        indicatorSeparator: (provided, state) => ({
            display:  'none', // Hide the indicator separator
        }),
        clearIndicator: (provided, state) => ({
            display: 'flex',
        }),
        dropdownIndicator: (provided, state) => ({
            display: state.isOverflowing ? 'none' : 'flex', // Hide when overflowing
        }),
    }
}